<template>
  <div class="container is-max-tablet has-text-centered">
    <section class="hero is-medium">
      <div class="hero-body">
        <p class="title">Scanner votre visage</p>
        <p class="subtitle">Suivez les instructions ci-dessous pour scanner votre visage.</p>
      </div>
    </section>

    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img src="../assets/images/persona.jpg" alt="Placeholder image"/>
        </figure>
      </div>
    </div>

    <router-link to="/results">
      <button class="button is-rounded is-white is-fullwidth" @click="handleSubmit">
        <span>Soumettre</span>
      </button>
    </router-link>

    <br><br><br><br><br><br><br>
  </div>
</template>

<script>
export default {
  name: 'UserFaceScanView'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero-body {
  margin-bottom: 1rem;
}
</style>
