<template>
  <div class="container is-max-tablet has-text-centered">
    <section class="hero is-medium">
      <div class="hero-body">
        <p class="title logo">Match My Skin</p>
        <p class="subtitle">Your personal skin assistant</p>
      </div>

      <div class="oidc-section">
        <router-link to="/scan">
          <button class="button is-rounded is-white is-fullwidth">
            <span>Connexion avec Google</span>
          </button>
        </router-link>

        <router-link to="/scan">
          <button class="button is-rounded is-white is-fullwidth">
            <span>Connexion avec Microsoft</span>
          </button>
        </router-link>

        <router-link to="/scan">
          <button class="button is-rounded is-white is-fullwidth">
            <span>Connexion avec Apple</span>
          </button>
        </router-link>

        <router-link to="/scan">
          <button class="button is-rounded is-white is-fullwidth">
            <span>Connexion avec Facebook</span>
          </button>
        </router-link>

      </div>
    </section>
  </div>

</template>

<script>

export default {
  name: 'LoginView'
}
</script>

<style scoped>
button {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem !important;
}

.hero-body {
  margin-bottom: 1rem;
}

.oidc-section {
  padding: 1.5rem;
  margin-bottom: 3rem !important;
}
</style>
