import { createMemoryHistory, createRouter } from 'vue-router'

import LoginView from './components/LoginView.vue'
import UserFaceScanView from './components/UserFaceScanView.vue'
import AboutView from './components/LoginView.vue'
import ResultView from './components/ResultView.vue'

const routes = [
    { path: '/', component: LoginView },
    { path: '/scan', component: UserFaceScanView },
    { path: '/about', component: AboutView },
    { path: '/results', component: ResultView },
    { path: '/:catchAll(.*)', redirect: '/' }, // Redirect unknown routes to LoginView
]

const router = createRouter({
    history: createMemoryHistory(),
    routes,
})

export default router