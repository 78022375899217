<template>
  <nav class="navbar">
    <div class="has-text-centered">
      <p class="logo">Match My Skin</p>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'MenuView'
}
</script>

<style scoped>
.logo {
  font-size: 1.0rem;
  padding: 1rem 0;
}

.navbar {
  margin-bottom: 2rem;
}
</style>