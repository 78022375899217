<template>
  <div class="container is-max-tablet">
    <MenuView></MenuView>
    <div id="app-content">
      <p>Après avoir analysé les résultats de votre scan facial, nous avons élaboré une routine personnalisée adaptée spécifiquement à vos besoins, répondant exactement à ce dont votre visage a besoin.</p>

      <br>
      <div>{{ matching }}</div>
      <br>

      <div class="columns is-mobile block-morning-routine is-flex is-vcentered">
        <div class="column is-four-fifths">
          <h1>Routine matinale</h1>
        </div>
        <div class="column has-text-right">
          <p class="emoji-morning-routine">☀️</p>
        </div>
      </div>

      <div class="product">
        <div class="columns is-mobile">
          <div class="column is-one-third">
            <figure class="image is-96x96">
              <img src="https://bulma.io/assets/images/placeholders/128x128.png" />
            </figure>
            <span class="tag is-link tag-bio-product">Produit bio</span>
          </div>
          <div class="column">
            <h4>Susane Faukmann</h4>
            <p>Ce gel nettoyant purifiant éliminera la saleté, l’excès de sébum, le maquillage et les impuretés de la surface et des pores.</p>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-full">
            <h5>Ingredients clés</h5>
            <div class="tags">
              <span class="tag is-white">Huile de tournesol</span>
              <span class="tag is-white">Huile de colza</span>
              <span class="tag is-white">Vitamine E</span>
            </div>

            <h5>Pourquoi vous en avez besoin</h5>
            <p>Nous avons identifié certaines impuretés sur votre visage, probablement causées par une exposition à la pollution de l’air extérieur.</p>
          </div>
        </div>
      </div>

      <div class="product">
        <div class="columns is-mobile">
          <div class="column is-one-third">
            <figure class="image is-96x96">
              <img src="https://bulma.io/assets/images/placeholders/128x128.png" />
            </figure>
          </div>
          <div class="column">
            <h4>Susane Faukmann</h4>
            <p>Ce gel nettoyant purifiant éliminera la saleté, l’excès de sébum, le maquillage et les impuretés de la surface et des pores.</p>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-full">
            <h5>Ingredients clés</h5>
            <div class="tags">
              <span class="tag is-white">Huile de tournesol</span>
              <span class="tag is-white">Huile de colza</span>
              <span class="tag is-white">Vitamine E</span>
            </div>

            <h5>Pourquoi vous en avez besoin ?</h5>
            <p>Nous avons identifié certaines impuretés sur votre visage, probablement causées par une exposition à la pollution de l’air extérieur.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import MenuView from "@/components/MenuView.vue";

export default {
  name: 'ResultView',
  components: {MenuView},
  data() {
    return {
      matching: {},
    };
  },
  methods: {
    async getProducts() {
      const { data } = await axios.get("https://api.match-my-skin.com/matching", {
        headers: {
          'Authorization': 'TntVFctiZzq74GJudz9PJH2F3N5rZUHiWXcOjYgS'
        }
      });
      this.matching = data;
    },
  },
  beforeMount() {
    this.getProducts();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.product {
  margin: 0.5rem 0;
  padding: 2rem 0;
  border-bottom: 1px solid #ccc5b9;
}

.product:last-child {
  border-bottom: 0;
}

.tag {
  margin-top: 0.5rem;
}

.tag-bio-product {
  background-color: #f4978e;
  font-weight: 500;
}

p {
  font-size: 1.1rem;
}

h4 {
  font-weight: bold;
}

h5 {
  color: #99582a;
}

.block-morning-routine h1 {
  font-family: "Delius", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 1.3rem;
  color: #fcca46;
}

.emoji-morning-routine {
  font-size: 2.5rem;
}

</style>
