<template>
  <router-view />
</template>

<script>
import './assets/style.scss'

export default {
  name: 'App',
}
</script>

<style>
.logo {
  font-family: "Pacifico", cursive;
  font-size: 2rem;
  font-weight: 400;
  font-style: normal;
  padding-bottom: 50px;
  color: #302E28;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app-content {
  padding: 1rem 2rem 3rem 2rem;
}

html, body {
  height: 100%;
  background-color: #F6F2EB;
}
</style>
